//Library
import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../../../../package.json';

// Icon
import iconCombineServices from 'src/assets/icons/ic_combine_services.svg';
import iconCombineServicesActive from 'src/assets/icons/ic_combine_services_active.svg';
import iconHouseholds from 'src/assets/icons/ic_house_holds.svg';
import iconHouseholdsActive from 'src/assets/icons/ic_house_holds_active.svg';
import iconLogo from 'src/assets/icons/ic_logo_sidebar.svg';
import iconServices from 'src/assets/icons/ic_services.svg';
import iconServicesActive from 'src/assets/icons/ic_services_active.svg';
import iconUserManagement from 'src/assets/icons/ic_user_management.svg';
import iconUserManagementActive from 'src/assets/icons/ic_user_management_active.svg';
import iconHumbugger from 'src/assets/icons/ic_humbugger.svg';
import iconOrders from 'src/assets/icons/ic_orders.svg';
import iconOrdersActive from 'src/assets/icons/ic_orders_active.svg';

// Components
// Features

// Utils
import { exactRouter } from 'src/utils/routers';
import { useWidthSize } from 'src/utils/help';

// Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const Sidebar = ({ showSideBar, setShowSideBar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const screenWidth = useWidthSize();

  const version = packageJson.version;

  // Check active icon menu
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const handleLogoClick = () => {
    navigate('/');
    setShowSideBar(false);
  };

  // Handle resize screen
  useEffect(() => {
    if (screenWidth >= 1024) {
      setShowSideBar(false);
    }
  }, [screenWidth, setShowSideBar]);

  return (
    <>
      <aside className={`${cx('sbWrapper')} ${showSideBar ? cx('sbShowSideBar') : cx('')}`}>
        <div className={cx('sbLogo')} onClick={handleLogoClick}>
          <img src={iconLogo} className={cx('sbLogoImg')} alt='iconLogo' />
          <div className={cx('right-logo')}>
            <h4 className={cx('top-logo')}>AdelCo</h4>
            <h5 className={cx('bottom-logo')}>Home Services Inc.</h5>
          </div>
        </div>
        <ul className=''>
          {/* <NavLink
            className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
            to={`${exactRouter.superAdmin.listService}`}
          >
            <li className={cx('li')} onClick={() => setShowSideBar(false)}>
              <img
                src={activeRoute(`${exactRouter.superAdmin.listService}`) ? iconServicesActive : iconServices}
                className={cx('img-li')}
                alt='icon'
              />
              <span>Services</span>
            </li>
          </NavLink> */}
          {/* <NavLink
            className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
            to={`${exactRouter.superAdmin.listSubService}`}
          >
            <li className={cx('li')} onClick={() => setShowSideBar(false)}>
              <img
                src={activeRoute(`${exactRouter.superAdmin.listSubService}`) ? iconServicesActive : iconServices}
                className={cx('img-li')}
                alt='icon'
              />
              <span>Sub Services</span>
            </li>
          </NavLink> */}
          {/* <NavLink
            className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
            to={`${exactRouter.superAdmin.combineService}`}
          >
            <li className={cx('li')} onClick={() => setShowSideBar(false)}>
              <img
                src={
                  activeRoute(`${exactRouter.superAdmin.combineService}`)
                    ? iconCombineServicesActive
                    : iconCombineServices
                }
                className={cx('img-li')}
                alt='icon'
              />
              <span>Combine Services</span>
            </li>
          </NavLink> */}
          <NavLink
            className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
            to={`${exactRouter.superAdmin.houseHolds}`}
          >
            <li className={cx('li')} onClick={() => setShowSideBar(false)}>
              <img
                src={activeRoute(`${exactRouter.superAdmin.houseHolds}`) ? iconHouseholdsActive : iconHouseholds}
                className={cx('img-li')}
                alt='icon'
              />
              <span>Households</span>
            </li>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
            to={`${exactRouter.superAdmin.orders}`}
          >
            <li className={cx('li')} onClick={() => setShowSideBar(false)}>
              <img
                src={activeRoute(`${exactRouter.superAdmin.orders}`) ? iconOrdersActive : iconOrders}
                className={cx('img-li')}
                alt='icon'
              />
              <span>Orders</span>
            </li>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
            to={`${exactRouter.superAdmin.listUser}`}
          >
            <li className={cx('li')} onClick={() => setShowSideBar(false)}>
              <img
                src={activeRoute(`${exactRouter.superAdmin.listUser}`) ? iconUserManagementActive : iconUserManagement}
                className={cx('img-li')}
                alt='icon'
              />
              <span>Users</span>
            </li>
          </NavLink>
        </ul>

        <div className={cx('sbVersion')}>Version: {version}</div>
        {!showSideBar && (
          <div className={cx('sbHumbugger')} onClick={() => setShowSideBar(!showSideBar)}>
            <img src={iconHumbugger} alt='humbugger' className={cx('sbHumbuggerIcon')} />
          </div>
        )}
      </aside>
      {showSideBar && <div className={cx('sbOverlay')} onClick={() => setShowSideBar(false)}></div>}
    </>
  );
};

export default Sidebar;
