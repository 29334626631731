import classNames from 'classnames/bind';
import React from 'react';
import BackGroundLogin from 'src/layouts/components/backgroundLogin';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

// Utils
import { exactRouter } from 'src/utils/routers';

// Images/Icons
import logo from 'src/assets/images/img_logo.png';

// Scss
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectAuthRole, selectAuthToken } from 'src/features/auth/authSlice';
import { CheckRoleUser } from 'src/utils/enum';

const cx = classNames.bind(styles);

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const token = useSelector(selectAuthToken);
  const role = useSelector(selectAuthRole);

  const handleLogoClicked = () => {
    navigate(`${exactRouter.auth}`);
  };

  return token && role === CheckRoleUser.SUPER_ADMIN ? (
    <Navigate to={exactRouter.superAdmin.listHouseholds} />
  ) : token && role === CheckRoleUser.ADMIN ? (
    <Navigate to={exactRouter.admin.listHouseholds} />
  ) : token && role === CheckRoleUser.EMPLOYEE ? (
    <Navigate to={exactRouter.employee.listHouseholds} />
  ) : (
    <div className={cx('wrapper')}>
      <div className={cx('content')}>
        <div className={cx('lg-logo')} onClick={handleLogoClicked}>
          <img src={logo} alt='logo' className={cx('lg-logo-img')} />
          AdelCo Home Services Inc.
        </div>
        <Outlet />
        <div className={cx('lg-copy')}>
          &copy; {new Date().getFullYear()} AdelCo Home Services Inc. All Rights Reserved.
        </div>
      </div>
      <div className={cx('right-side')}>
        <BackGroundLogin />
      </div>
    </div>
  );
};

export default AuthLayout;
